<template>
  <div class="h-screen">
    <div v-if="isMobileLandscape">
      <div class="w-full grid gap-2">
        <p class="text-lg text-black text-center font-bold mt-10">
          Rotate your phone
        </p>
        <div class="place-self-center">
          <lottie-animation
            path="lotties/rotate-portrait.json"
            :loop="true"
            :autoPlay="true"
            :speed="0.5"
            :width="200"
            :height="200"
          />
        </div>
        <p class="text-sm text-white text-center">Please use portrait mode.</p>
      </div>
    </div>
    <div v-else>
      <!-- Default Layout -->
      <div>
        <Loader v-if="isPageLoading" />
        <router-view v-else :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/elements/Loader.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Loader,
    LottieAnimation,
  },
  data() {
    return {
      isDesktop: false,
      isMobilePortrait: false,
      isMobileLandscape: false,
    };
  },
  computed: { ...mapGetters(["isPageLoading"]) },
  created() {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
  methods: {
    updateScreenSize() {
      this.isDesktop = window.matchMedia("(min-width: 768px)").matches;
      if (!this.isDesktop) {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const orientation = window.matchMedia(
          "(orientation: landscape)"
        ).matches;
        this.isMobilePortrait = isMobile && !orientation;
        this.isMobileLandscape = isMobile && orientation;
      }
    },
  },
};
</script>

<style>
body,
html {
  background-color: #000000;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
